.word:hover + .border-fill::after {
  animation: border-fill-animation 1s ease-in-out forwards;
}

.border-fill {
  position: relative;
  overflow: hidden;
}

.border-fill::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #f27303;
}
.showMENU{
  display: flex;
 }
@keyframes border-fill-animation {
  0% {
    width: 0%;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
}
/* @media (max-width: 1375px) {
  .mobile-ul{
    display: none;
  }
} */

@media (max-width: 1510px) {
  .rep-navbar{
    gap:5px
  }
}
@media (max-width: 590px) {
  .main{
  margin: 3rem;
    }
    .heading{
      font-size: 40px;
    }
    .highlight{
      font-size: 10px;
      padding: 0.2rem 0.7rem;
    }
    .mobile-header{
      padding-inline: 2rem;
    }
}
@media (max-width: 480px) {
  
}